(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        selector.on("click", "[data-toggle-more]", function() {
            let elm = $(this);
            let elm_text = elm.data("toggle-more").split(",");

            let content = elm.closest(".elm_item_text_more").find(".part_ui_wsw");
            if (!content.hasClass("state--active")) {
                content.addClass("state--active");
                content.slideDown(500);
                elm.addClass("state--selected").find("span").text(elm_text[1]);
            } else {
                content.removeClass("state--active");
                content.slideUp(500);
                elm.removeClass("state--selected").find("span").text(elm_text[0]);
            }
        });
    }
})("comp_base_timeline");