(function($) {
    
    doc.on('change','[data-component-period]',function(e) {
        e.preventDefault();        
        let elm = $(this);
        let url = elm.attr('data-href');
        let period = $('[data-component-period]').val();
        
        if(!url || !url.length) {
            url = elm.attr('href');
        }

        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'get',
            dataType: 'json',
            data: {'period': period},
            cache: false
        }).done(function (payload) {
            fn_ajaxHandler(payload);
        });
    });
    
    doc.on('click','a[data-component-ajax]',function(e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if(!url || !url.length) {
            url = $(this).attr('href');
        }

        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload,function() {
                ScrollReveal().sync();
            });
        });
    });
    
    cssLoaded(function(){
        $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();
    });

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    $.libInit(".part_ui_link, .part_ui_btn, .part_ui_checkbox, .part_ui_radio", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
        html.addClass("safari")
    }

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();
})(jQuery);